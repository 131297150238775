import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { UploadingResult, User } from 'src/app/interfaces';
import { ProductsService } from 'src/app/services/products.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-batch-select',
  templateUrl: './batch-select.component.html',
  styleUrls: ['./batch-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BatchSelectComponent implements OnInit {
  isLoading = false;
  isLoadingResults = false;
  files: NgxFileDropEntry[] = [];
  infoData = '';
  fileToImport;
  type = 'uploading';
  user;
  uploadingResult: UploadingResult;

  constructor(
    public dialogRef: MatDialogRef<BatchSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastr: ToastrService,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    if (this.data) {
      if (this.data.user) {
        this.user = this.data.user;
      }
    }
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // console.log(droppedFile.relativePath, fileEntry);
          // console.log(droppedFile.relativePath, file);
          if (file.size < 1048576) {
            if (file.type === 'text/plain' || /(\.csv)/g.test(file.name)) {
              this.fileToImport = file;
              this.infoData = file.name;
              this.sendData(this.fileToImport);
            } else {
              this.toastr.warning(
                `Wrong file type "${droppedFile.relativePath}"`,
                'Attention!'
              );
            }
          } else {
            this.toastr.warning(
              `Max file size ${Math.round(1048576 / 1024 / 1024)}Mb.`,
              'Attention!'
            );
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  async sendData(file) {
    try {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('file', file);
      let result = await this.productsService
        .batchSelect(this.user.teamId, formData)
        .toPromise();
      this.uploadingResult = result.body;
      this.isLoading = false;
      this.type = 'uploaded-result';
    } catch (error) {
      console.log(error);
      this.toastr.error(
        error.error && error.error.message
          ? error.error.message
          : 'Something went wrong.',
        'Oops!'
      );
      this.isLoading = false;
      this.type = 'uploading';
    }
  }

  downloadReport() {
    this.isLoadingResults = true;
    this.productsService.getBatchSelectFile(this.uploadingResult).subscribe(
      (result) => {
        // console.warn('result getBatchSelectFile ++');

        let newBlob = new Blob([result.body], { type: 'text/csv' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = 'Match_Report';
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        this.isLoadingResults = false;

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (error) => {
        // console.warn('error getReportPDF', error);
        this.toastr.error(
          'Something went wrong. ' +
            (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
        this.isLoadingResults = false;
      }
    );
  }

  async addMatchedProducts() {
    try {
      this.isLoadingResults = true;
      await this.productsService
        .addBatchSelectFile(this.uploadingResult)
        .toPromise();
      this.toastr.success('', 'Success');
      this.isLoadingResults = false;
      this.dialogRef.close('Success');
    } catch (error) {
      console.log(error);
      this.toastr.error('Something went wrong', 'Oops!');
      this.isLoadingResults = false;
    }
  }

  downloadCsvExample() {
    // let partnumbers = ['913161', '913160', '912161', '912160'];
    // let csvArray = partnumbers.join('\r\n');

    // let blob = new Blob([csvArray], { type: 'text/csv' });
    // saveAs(blob, 'csv-example.csv');
    this.isLoadingResults = true;
    this.productsService.downloadExample().subscribe(
      (result) => {
        // console.warn('result downloadCsvExample ++');

        let newBlob = new Blob([result.body], { type: 'text/csv' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = 'CSV Example';
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        this.isLoadingResults = false;

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (error) => {
        // console.warn('error downloadCsvExample', error);
        this.toastr.error(
          'Something went wrong. ' +
            (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
        this.isLoadingResults = false;
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }
}
