import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ceateDataFromBlob, isItemNotInArray } from 'src/app/global-functions';
import { ImageCropComponent } from 'src/app/modals/image-crop/image-crop.component';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { MediaService } from 'src/app/services/media.service';
import { TeamDistributorsService } from 'src/app/services/team-distributors.service';
import { UserService } from 'src/app/services/user.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-distributor-info',
  templateUrl: './distributor-info.component.html',
  styleUrls: ['./distributor-info.component.scss'],
})
export class DistributorInfoComponent implements OnInit {
  isLoading = false;
  isLoadingLogo = false;
  user;
  distributors;
  distributorData;
  teamDistributorName;
  teamDistributorLogo;
  teamDistributorLogoName;
  aSub: Subscription;
  headerTitle = 'Settings';

  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
    private mediaService: MediaService,
    private userService: UserService,
    private headerDataService: HeaderDataService,
    private teamDistributorsService: TeamDistributorsService
  ) {}

  ngOnInit() {
    this.aSub = this.userService.userChanged$.subscribe((user) => {
      this.user = user;
      if (user) {
        if (user.distributorName) {
          this.getTeamDistributorsData();
        }
      }
    });
    this.headerDataService.setTitle(this.headerTitle);
  }

  ngOnDestroy() {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

  getTeamDistributorsData() {
    this.isLoading = true;
    this.teamDistributorsService
      .getTeamDistributorCurrent()
      .subscribe((data) => {
        this.distributorData = data.body;
        this.isLoading = false;
        if (this.distributorData) {
          this.teamDistributorName = this.distributorData.name;
          this.teamDistributorLogoName = this.distributorData.pdfLogo;
          if (this.distributorData.pdfLogo) {
            this.getLogo(this.distributorData);
          }
        }
      });
  }

  fileChangeEvent(event) {
    let file = event.target.files[0];
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png'
    ) {
      if (file.size < 10000000) {
        const dialogRef = this.dialog.open(ImageCropComponent, {
          width: '94vw',
          maxWidth: '600px',
          maxHeight: '94vh',
          panelClass: 'image-crop-panel',
          data: {
            event,
            format: 'jpeg',
          },
        });

        dialogRef.afterClosed().subscribe((image) => {
          if (image) {
            this.teamDistributorLogo = image;
            this.teamDistributorLogoName = file.name;
          }
        });
      } else {
        this.toastr.warning(
          `Max image size ${Math.round(10000000 / 1024)}kb.`,
          'Attention!'
        );
      }
    } else {
      this.toastr.warning('Wrong file type', 'Attention!');
    }
  }

  clearImage() {
    this.teamDistributorLogo = null;
    this.teamDistributorLogoName = '';
  }

  save() {
    let data: any = {
      name: '',
      photo: null,
    };

    let errors = [];

    if (
      !this.teamDistributorName &&
      isItemNotInArray(errors, 'Distributor Name is required.')
    ) {
      errors.push('Distributor Name is required.');
    }

    if (errors.length > 0) {
      let list = '<ul>';
      errors.forEach((error) => {
        list += `<li>${error}</li>`;
      });
      list += '<ul>';

      this.toastr.warning(list, 'Attention!', {
        enableHtml: true,
        timeOut: 4000,
      });
    } else {
      data.name = this.teamDistributorName;
      if (this.distributorData) {
        data.id = this.distributorData.id;
      }

      if (this.teamDistributorLogo && this.teamDistributorLogoName) {
        if (this.teamDistributorLogoName !== this.distributorData.pdfLogo) {
          data.photo = {
            media: this.teamDistributorLogo.replace(
              /data:(image|application)\/(jpeg|png|jpg|json);base64/,
              ''
            ),
            name: uuid.v4() + '__' + this.teamDistributorLogoName,
          };
        } else {
          data.photo = {
            name: this.teamDistributorLogoName,
          };
        }
      }

      // console.warn('data >> ', data);

      this.isLoading = true;
      this.teamDistributorsService
        .updateTeamDistributor(this.distributorData.id, data)
        .subscribe(
          (response) => {
            // console.log(response);
            this.isLoading = false;
            this.toastr.info(
              'Distributor Information was successfully saved!',
              'Info'
            );
          },
          (error) => {
            // console.warn('updateDistributor err ->', error); // example
            this.toastr.error(
              'Something went wrong. ' +
                (error.error && error.error.errors
                  ? error.error.errors.join(', ')
                  : error.error.message
                  ? error.error.message
                  : ''),
              'Oops!'
            );
            this.isLoading = false;
          }
        );
    }
  }

  getLogo(distributor) {
    this.isLoadingLogo = true;
    this.mediaService.getMedia(distributor.pdfLogo, 'distributor').subscribe(
      (result) => {
        if (result.status === 200) {
          ceateDataFromBlob(result.body).then(
            (data) => {
              this.teamDistributorLogo = data;
              this.isLoadingLogo = false;
            },
            (err) => {
              this.isLoadingLogo = false;
              // console.warn('ceateDataFromBlob err ', err);
            }
          );
        }
      },
      (err) => {
        this.isLoadingLogo = false;
        // console.warn('mediaService.getMedia err ', err);
      }
    );
  }
}
