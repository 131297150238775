import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidebarDataService } from 'src/app/services/sidebar-data.service';

@Component({
  selector: 'app-manufacturers',
  templateUrl: './manufacturers.component.html',
  styleUrls: ['./manufacturers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManufacturersComponent implements OnInit {
  activeSidebarSection = 'Products';

  constructor(private sidebarData: SidebarDataService) {}

  ngOnInit() {
    this.sidebarData.setOpenedSection(this.activeSidebarSection);
  }
}
