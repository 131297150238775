import { Injectable } from '@angular/core';
import { BuildService } from './build.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  page = 0;
  size = 20;

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  getAndroid(): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/applications/android`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  postAndroidInfo(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/applications/android', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  getIos(): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/applications/ios`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  postIosInfo(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/applications/ios', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  getDesktop(): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/applications/desktop`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  postDesktopInfo(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/applications/desktop', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }
}
