import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MAT_DIALOG_DATA,
} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ceateDataFromBlob } from 'src/app/global-functions';
import { Category, ProductRecord } from 'src/app/interfaces';
import { CategoriesService } from 'src/app/services/categories.service';
import { MediaService } from 'src/app/services/media.service';
import { ProductsPickerService } from 'src/app/services/products-picker.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-picker',
  templateUrl: './product-picker.component.html',
  styleUrls: ['./product-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductPickerComponent implements OnInit {
  isLoading = false;
  isLoadingProducts = false;
  isFilteredData = true;
  filterByName = '';
  searchField = '';
  showBase = true;
  showSearch = false;
  showCategoryProducts = false;
  isForce = false;
  allProducts = [];
  allCategories = [];
  allCategoryProductsLength;
  productsToShow = [];
  categoriesToShow = [];
  currentCategory;
  searchResults = [];
  typingTimer;
  doneTypingInterval = 777;
  searching = false;
  sortTypes = [
    { id: 1, name: 'A > Z' },
    { id: 2, name: 'Z > A' },
    { id: 3, name: 'Cheap first' },
    { id: 4, name: 'Expensive First' },
  ];
  sortByType = null;
  @ViewChild('mp1', { static: true }) mp1: MatPaginator;
  @ViewChild('mp2', { static: true }) mp2: MatPaginator;
  user;

  constructor(
    public dialogRef: MatDialogRef<ProductPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public productsBaseService: ProductsService,
    public productsService: ProductsPickerService,
    private categoriesService: CategoriesService,
    private mediaService: MediaService
  ) {}

  ngOnInit() {
    this.productsBaseService.setPick(true);
    this.productsService.setPick(true);
    setTimeout(() => {
      this.productsBaseService.setSize(16);
    }, 500);
    if (this.data) {
      if (this.data.user) {
        this.user = this.data.user;
      }
    }
    this.getCategories();
  }

  ngOnDestroy() {
    this.productsBaseService.setSize(10);
    this.productsBaseService.setPick(false);
    this.productsService.setPick(false);
  }

  async getCategories() {
    try {
      this.isLoading = true;
      this.categoriesService.setSize(1000);
      let localCategories = await this.categoriesService
        .getCategories()
        .toPromise();
      this.allCategories = localCategories.body.data;
      this.prepareDataItems(this.allCategories);
      this.categoriesService.setSize(20);
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.toastr.error('Something went wrong', 'Oops!');
      this.isLoading = false;
    }
  }

  confirm(data) {
    this.dialogRef.close(data);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  showSearchBlock() {
    this.searchField = '';
    this.searchResults = [];
    this.showBase = false;
    this.showSearch = true;
    this.showCategoryProducts = false;
  }

  showBaseBlock() {
    this.showBase = true;
    this.showSearch = false;
    this.showCategoryProducts = false;
    this.mp2.length = 0;
    this.mp1.pageIndex = 0;
  }

  async showCategoryBlock(category: Category) {
    this.currentCategory = category;
    this.showBase = false;
    this.showSearch = false;
    this.showCategoryProducts = true;
    await this.getProductsData();
  }

  getProductsDataInt() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(async () => {
      await this.getProductsData();
    }, this.doneTypingInterval);
  }

  getProductsBaseDataInt() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(async () => {
      await this.getProductsBaseData();
    }, this.doneTypingInterval);
  }

  async getProductsBaseData() {
    try {
      this.isLoadingProducts = true;
      this.productsBaseService.setPage((this.mp2 && this.mp2.pageIndex) || 0);
      this.productsBaseService.setSize(
        (this.mp2 && this.mp2.pageSize) || this.productsBaseService.getSize()
      );
      this.productsBaseService.setName(this.searchField);
      this.productsBaseService.setTeamId(this.user.teamId);
      let products = await this.productsBaseService.getProducts().toPromise();
      this.searchResults = products.body.data;
      this.prepareDataItems(this.searchResults);
      if (this.mp2) {
        this.mp2.length = products.body.metadata.totalElements;
      }
      this.isLoadingProducts = false;
    } catch (error) {
      console.log(error);
      this.toastr.error('Something went wrong', 'Oops!');
      this.isLoadingProducts = false;
    }
  }

  async getProductsData() {
    try {
      this.isLoadingProducts = true;
      this.productsService.setPage((this.mp1 && this.mp1.pageIndex) || 0);
      this.productsService.setSize(
        (this.mp1 && this.mp1.pageSize) || this.productsService.getSize()
      );
      this.productsService.setTeamId(this.user.teamId);
      let products = await this.productsService
        .getProductsByCategory(this.currentCategory.id)
        .toPromise();
      this.productsToShow = products.body.data;
      this.prepareDataItems(this.productsToShow);
      if (this.mp1) this.mp1.length = products.body.metadata.totalElements;
      this.allCategoryProductsLength = products.body.metadata.totalElements;
      this.isLoadingProducts = false;
    } catch (error) {
      console.log(error);
      this.toastr.error('Something went wrong', 'Oops!');
      this.isLoadingProducts = false;
    }
  }

  prepareDataItems(arr) {
    arr = arr.map((item: ProductRecord) => {
      if (item.photo) {
        this.mediaService.getMedia(item.photo, 'product').subscribe(
          (result) => {
            if (result.status === 200) {
              ceateDataFromBlob(result.body).then(
                (data) => {
                  item.photoData = data;
                },
                (err) => {
                  // console.warn('ceateDataFromBlob err ', err);
                }
              );
            }
          },
          (err) => {
            // console.warn('mediaService.getMedia err ', err);
          }
        );
      }

      return item;
    });
  }

  applyFilter(filterValue: string) {
    clearTimeout(this.typingTimer);
    this.searching = true;
    this.typingTimer = setTimeout(async () => {
      try {
        await this.getProductsBaseData();
        this.searching = false;
      } catch (error) {
        console.log(error);
        this.toastr.error('Something went wrong', 'Oops!');
        this.searching = false;
      }
    }, this.doneTypingInterval);
  }

  async sortCategoryProducts(e) {
    switch (e.value.name) {
      case 'A > Z':
        this.productsService.setOrderBy('name');
        this.productsService.setOrdering('asc');
        break;
      case 'Z > A':
        this.productsService.setOrderBy('name');
        this.productsService.setOrdering('desc');
        break;
      case 'Cheap first':
        this.productsService.setOrderBy('msrp');
        this.productsService.setOrdering('asc');
        break;
      case 'Expensive First':
        this.productsService.setOrderBy('msrp');
        this.productsService.setOrdering('desc');
        break;
      default:
        break;
    }
    await this.getProductsData();
  }

  addProduct(product) {
    this.dialogRef.close(product);
  }
}
