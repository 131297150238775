import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { ConfirmPopupComponent } from "src/app/dialogs/confirm-popup/confirm-popup.component";
import { ceateDataFromBlob } from "src/app/global-functions";
import { TeamDistributorComponent } from "src/app/modals/team-distributor/team-distributor.component";
import { ViewTeamDistributorLogoComponent } from "src/app/modals/view-team-distributor-logo/view-team-distributor-logo.component";
import { HeaderDataService } from "src/app/services/header-data.service";
import { MediaService } from "src/app/services/media.service";
import { TeamDistributorsService } from "src/app/services/team-distributors.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-team-distributors",
  templateUrl: "./team-distributors.component.html",
  styleUrls: ["./team-distributors.component.scss"],
})
export class TeamDistributorsComponent implements OnInit {
  headerTitle = "STUD-E TEAM Distributrors Management";
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  nowDate = new Date();
  isLoadingResults = false;
  teamDistributorsData = [];
  dataSource: any;
  displayedColumns: string[] = [
    "name",
    "adminDistributorName",
    "representatives",
    "products",
    "teamDistributorLogo",
    "actions",
  ];
  user = null;
  aSub: Subscription;

  constructor(
    public teamDistributorsService: TeamDistributorsService,
    public headerDataService: HeaderDataService,
    private toastr: ToastrService,
    private userService: UserService,
    private mediaService: MediaService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.aSub = this.userService.userChanged$.subscribe((user) => {
      this.user = user;
    });
    this.headerDataService.setTitle(this.headerTitle);
    this.getTeamDistributorsData();
  }

  ngOnDestroy() {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

  getTeamDistributorsData() {
    this.isLoadingResults = true;
    this.teamDistributorsService.setPage(this.paginator.pageIndex);
    this.teamDistributorsService.setSize(
      this.paginator.pageSize || this.teamDistributorsService.getSize()
    );
    this.teamDistributorsService.getTeamDistributors().subscribe(
      (response) => {
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoadingResults = false;
      },
      (error) => {
        this.paginator.length = 1000;
        this.prepareDataSourse([
          { name: 1 },
          { name: 2 },
          { name: 3 },
          { name: 4 },
        ]);
        this.isLoadingResults = false;
        // console.warn('getteamDistributorsData err ->', error); // example
        this.toastr.error(
          "Something went wrong. " +
            (error.error && error.error.message ? error.error.message : ""),
          "Oops!"
        );
      }
    );
  }

  prepareDataSourse(data: any[]) {
    // console.warn('prepareDataSourse data -> ', data); // example
    this.teamDistributorsData = JSON.parse(JSON.stringify(data)).map((item) => {
      return item;
    });

    this.dataSource = new MatTableDataSource(this.teamDistributorsData);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        // case "creationTime":
        //   return new Date(item.clientCreated);
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.teamDistributorsService.setOrderBy(event.active);
    this.teamDistributorsService.setOrdering(event.direction);
    this.getTeamDistributorsData();
  }

  deleteTeamDistributor(distributor) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: "auto",
      data: {
        title: "Delete TEAM Distributor",
        message: "Are you sure you want to delete entry?",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      },
    });

    dialogRef.afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        this.teamDistributorsService
          .deleteTeamDistributor(distributor.id)
          .subscribe(
            (result) => {
              // console.warn('result DEL report', result);
              this.getTeamDistributorsData();
            },
            (error) => {
              // console.warn('error DEL report', error);
              this.toastr.error(
                "Something went wrong. " +
                  (error.error && error.error.message
                    ? error.error.message
                    : ""),
                "Oops!"
              );
            }
          );
      }
    });
  }

  create() {
    const dialogRef = this.dialog.open(TeamDistributorComponent, {
      width: "40rem",
      maxWidth: "94vw",
      maxHeight: "90vh",
      panelClass: "team-distributor-modal-panel",
      data: {
        type: "new",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "success") {
        this.getTeamDistributorsData();
      }
    });
  }

  view(distributor) {
    const dialogRef = this.dialog.open(TeamDistributorComponent, {
      width: "40rem",
      maxWidth: "94vw",
      maxHeight: "90vh",
      panelClass: "team-distributor-modal-panel",
      data: {
        type: "edit",
        distributor,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "success") {
        this.getTeamDistributorsData();
      }
    });
  }

  viewLogo(distributor) {
    this.mediaService.getMedia(distributor.pdfLogo, "distributor").subscribe(
      (result) => {
        if (result.status === 200) {
          ceateDataFromBlob(result.body).then(
            (data) => {
              const dialogRef = this.dialog.open(
                ViewTeamDistributorLogoComponent,
                {
                  maxWidth: "50vw",
                  maxHeight: "90vh",
                  data: {
                    title: "TEAM Distributor Logo",
                    image: data,
                  },
                }
              );
            },
            (err) => {
              // console.warn('ceateDataFromBlob err ', err);
            }
          );
        }
      },
      (err) => {
        // console.warn('mediaService.getMedia err ', err);
      }
    );
  }
}
