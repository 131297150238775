import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root'
})
export class DistributorsService {
  page = 0;
  size = 10;
  orderBy = 'name';
  ordering = 'desc';
  name = '';

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getName() {
    return this.name;
  }

  getDistributors(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() +
        `web/distributors?page=${this.page}&size=${this.size}&orderBy=${this.orderBy}&ordering=${this.ordering}${
          this.name ? '&name=' + this.name : ''
        }`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  getDistributor(distributorId): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/distributors/${distributorId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  createDistributor(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/distributors/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  updateDistributor(distributorId, body): Observable<any> {
    return this.http.put(this.build.getApiLink() + `web/distributors/${distributorId}`, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  deleteDistributor(distributorId: string | number): Observable<any> {
    return this.http.delete(this.build.getApiLink() + `web/distributors/${distributorId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  getDistributorsNames(): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/distributors/names`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  importDistributorCsv(distributorId, body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/distributors/import/' + distributorId, body, {
      headers: new HttpHeaders().set('Content-Type', 'multipart/form-data'),
      observe: 'response'
    });
  }
}
