import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageCropComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isRounded = false;
  aspectRatio = 0;
  maintainAspectRatio = false;
  wh = 150;
  format = 'png';

  constructor(public dialogRef: MatDialogRef<ImageCropComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    if (this.data) {
      if (this.data.event) {
        this.imageChangedEvent = this.data.event;
      }
      if (this.data.rounded) {
        this.isRounded = this.data.rounded;
      }
      if (this.data.aspectRatio) {
        this.aspectRatio = this.data.aspectRatio;
      }
      if (this.data.maintainAspectRatio) {
        this.maintainAspectRatio = this.data.maintainAspectRatio;
      }
      if (this.data.format) {
        this.format = this.data.format;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  confirm() {
    this.dialogRef.close(this.croppedImage);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
