export default [
  { id: -1, name: "All Roles" },
  // { id: 0, name: "Administrator", type: "ADMIN" },
  // { id: 1, name: "Justrite Manager", type: "J_MANAGER" },
  // { id: 2, name: "Agency Manager", type: "A_MANAGER" },
  // { id: 3, name: "Representative", type: "REPRESENTATIVE" },
  { id: 4, name: "Admin Distributor", type: "ADMIN_DISTRIBUTOR" },
  {
    id: 5,
    name: "Representative Distributor",
    type: "REPRESENTATIVE_DISTRIBUTOR",
  },
];
