import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidebarDataService } from '../../services/sidebar-data.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: '[app-sidebar]',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  logoSrc = 'assets/images/jr-logo@2x.png';
  title = 'Justrite';
  user;
  aSub: Subscription;
  sidebarList: {}[] = [
    // {
    //   navList: [
    //     {
    //       icon: 'newspaper',
    //       title: 'Reports',
    //       link: '/reports',
    //     },
    //     {
    //       icon: 'file-alt',
    //       title: 'Executive Summary',
    //       link: '/report-executive-summary',
    //     },
    //   ],
    // },
    // {
    //   title: 'Content Management',
    //   products: [
    //     {
    //       icon: 'shopping-bag',
    //       title: 'Products',
    //       navList: [
    //         {
    //           link: '/products',
    //           text: 'Products',
    //         },
    //         {
    //           link: '/categories',
    //           text: 'Categories',
    //         },
    //         {
    //           link: '/manufacturers',
    //           text: 'Manufacturers',
    //           disabled: true
    //         },
    //         {
    //           link: '/import-data',
    //           text: 'Import Data',
    //           disabled: true
    //         }
    //       ],
    //     },
    //   ],
    //   navList: [
    //     {
    //       icon: 'shopping-bag',
    //       title: 'Enabled Products',
    //       link: '/products',
    //     },
    //     {
    //       icon: 'truck',
    //       title: 'Team Distributors',
    //       link: '/team-distributors',
    //     },
    //     {
    //       icon: 'users',
    //       title: 'Users',
    //       link: '/users',
    //     },
    //   ],
    //   users: [
    //     {
    //       icon: 'users',
    //       title: 'Users',
    //       navList: [
    //         {
    //           link: '/users',
    //           text: 'Users',
    //         },
    //         {
    //           link: '/agency-management',
    //           text: 'Agency Management',
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   title: 'Data tempaltes',
    //   navList: [
    //     {
    //       icon: 'database',
    //       title: 'Report Information',
    //       link: '/report-information',
    //       disabled: true
    //     },
    //     {
    //       icon: 'star',
    //       title: 'Recommended Products',
    //       link: '/recommended-products',
    //       disabled: true
    //     }
    //   ]
    // },
    // {
    //   title: 'Application Management',
    //   navList: [
    //     {
    //       icon: 'mobile-alt',
    //       title: 'Application Updates',
    //       link: '/application-updates',
    //     },
    //   ],
    // },
    // {
    //   title: 'Settings',
    //   navList: [
    //     {
    //       icon: 'user',
    //       title: 'Distributor Information',
    //       link: '/distributor-information'
    //     }
    //   ]
    // }
  ];

  currentNavItem = '';

  constructor(
    public sidebarData: SidebarDataService,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.sidebarData.openedSectionUpdated.subscribe((title) => {
      this.currentNavItem = title;
    });
  }

  ngOnInit() {
    this.aSub = this.userService.userChanged$.subscribe((user) => {
      this.user = user;
      if (this.user) {
        if (this.user.role == 'ADMIN') {
          this.sidebarList = [
            {
              navList: [
                {
                  icon: 'newspaper',
                  title: 'Reports',
                  link: '/reports',
                },
                {
                  icon: 'file-alt',
                  title: 'Executive Summary',
                  link: '/report-executive-summary',
                },
              ],
            },
            {
              title: 'Content Management',
              navList: [
                {
                  icon: 'shopping-bag',
                  title: 'Enabled Products',
                  link: '/products',
                },
                {
                  icon: 'truck',
                  title: 'Team Distributors',
                  link: '/team-distributors',
                },
                {
                  icon: 'users',
                  title: 'Users',
                  link: '/users',
                },
              ],
            },
          ];
        }
        if (this.user.role == 'ADMIN_DISTRIBUTOR') {
          this.sidebarList = [
            {
              navList: [
                {
                  icon: 'newspaper',
                  title: 'Reports',
                  link: '/reports',
                },
                {
                  icon: 'file-alt',
                  title: 'Executive Summary',
                  link: '/report-executive-summary',
                },
              ],
            },
            {
              title: 'Content Management',
              navList: [
                {
                  icon: 'shopping-bag',
                  title: 'Enabled Products',
                  link: '/products',
                },
                {
                  icon: 'users',
                  title: 'Users',
                  link: '/users',
                },
              ],
            },
            {
              title: 'Settings',
              navList: [
                {
                  icon: 'user',
                  title: 'Distributor Information',
                  link: '/distributor-information',
                },
              ],
            },
          ];
        }
        if (this.user.role == 'J_MANAGER') {
          this.sidebarList = [
            {
              navList: [
                {
                  icon: 'newspaper',
                  title: 'Reports',
                  link: '/reports',
                },
              ],
            },
          ];
        }
      }
    });

    this.currentNavItem = this.sidebarData.getOpenedSection();
  }

  setCurrentNavItem(title: string) {
    this.sidebarData.setOpenedSection(title);
  }

  logout() {
    this.authService.logout();
  }
}
