import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsPickerService {
  page = 0;
  size = 16;
  orderBy = 'name';
  ordering = 'desc';
  name = '';
  teamId = '';
  pick = false;

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getName() {
    return this.name;
  }

  public setTeamId(name: string) {
    this.teamId = name;
  }

  public getTeamId() {
    return this.teamId;
  }

  public setPick(bool: boolean) {
    this.pick = bool;
  }

  public getPick() {
    return this.pick;
  }

  getProductsByCategory(categoryId): Observable<any> {
    return this.http.get(
      this.build.getApiLink() +
        `web/products?page=${this.page}&size=${
          this.size
        }&category=${categoryId}${
          this.teamId ? '&teamId=' + this.teamId : ''
        }&orderBy=${this.orderBy}&ordering=${this.ordering}${
          this.pick ? '&pick=' + this.pick : ''
        }`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }
}
