import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainContentComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(
      (user) => {
        let authorizedRoles = [
          'J_MANAGER',
          'ADMIN',
          'ADMIN_DISTRIBUTOR',
          // 'REPRESENTATIVE_DISTRIBUTOR',
        ];
        if (user.id && authorizedRoles.some((e) => e == user.role)) {
          this.authService.setAuthenticated(true);
          this.router.navigate([this.authService.redirectUrl]);
        } else {
          window.localStorage.removeItem('SL-UD');
          this.authService.setAuthenticated(false);
          this.router.navigate(['/login']);
        }
        if (!authorizedRoles.some((e) => e == user.role)) {
          this.toastr.warning('Dashboard is for Authorized Users Only');
        }
      },
      (error) => {
        // console.warn('main-content.comp-init user err -> ', error);
        // TODO: Pretify
        window.localStorage.removeItem('SL-UD');
        this.authService.setAuthenticated(false);
        this.router.navigate(['/login']);
      }
    );
  }
}
