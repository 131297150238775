import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderDataService {
  title = 'Justrite';
  titleUpdated: Subject<any> = new Subject<any>();

  constructor() {}

  public setTitle(text: string): void {
    this.title = text;
    this.titleUpdated.next(this.title);
  }

  public getTitle(): string {
    return this.title;
  }
}
