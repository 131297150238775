import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {
  faq = [
    {
      question: '1. How do I download the APP to my tablet or mobile phone?',
      answer: 'Please go to this link <a href="https://studeteam.justrite.com/download/index.html" target="_blank">https://studeteam.justrite.com/download/index.html</a> to download the App.'
    },
    {
      question: '2. What is the website address for the desktop version.',
      answer: 'The website link is <a href="https://studeteam.justrite.com/download/index.html" target="_blank">https://studeteam.justrite.com/download/index.html</a>'
    },
    {
      question: '3. I forgot my password?',
      answer: 'Click on Forgot password to reset your password. You will get an email to reset your password.'
    },
    {
      question: '4. I cannot log on, who do I contact for help?',
      answer: 'Please email <a href="mailto:studehelp@justrite.com">studehelp@justrite.com</a>'
    },
    {
      question: '5. I need a new distrbutor added to the list. Who do I contact to do that?',
      answer: 'Please email <a href="mailto:studehelp@justrite.com">studehelp@justrite.com</a>'
    },
    {
      question: '6. My App keeps crashing on me. What do I do?',
      answer:
        'Remove the STUDE App from your device, download it from <a href="https://studeteam.justrite.com/download/index.html" target="_blank">https://studeteam.justrite.com/download/index.html</a> and install on your device.'
    },
    {
      question: '7. Photos are missing from the App.',
      answer:
        'If there is no image for the product it will show up as No Image Available. If an image is missing then an X will appear. Please send an email to <a href="mailto:studehelp@justrite.com">studehelp@justrite.com</a> and indicate what image is missing.'
    },
    {
      question: '8. I want to edit my report but I do not see how to do it?',
      answer:
        'On your desktop, click on Home and you will see the reports you have done. You will see three dots in the upper right hand of your report. Click on edit and you can now edit your document. If you have submitted your document, it is no longer editable.'
    },
    {
      question: '9. Where can I find the Accuform Facility Identification Survey PDF?',
      answer:
        'Here: <a href="http://www.accuform.com/files/damObject/file/Accuform_Safety_Sign_Survey_Email.pdf" target="_blank">http://www.accuform.com/files/damObject/file/Accuform_Safety_Sign_Survey_Email.pdf</a>'
    }
  ];
  constructor() {}

  ngOnInit() {}
}
