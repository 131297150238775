import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  page = 0;
  size = 20;

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  getCategories(): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/categories?page=${this.page}&size=${this.size}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  getCategory(categoryId): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/categories/${categoryId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  createCategory(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/categories/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  updateCategory(categoryId, body): Observable<any> {
    return this.http.put(this.build.getApiLink() + `web/categories/${categoryId}`, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  deleteCategory(categoryId: string | number): Observable<any> {
    return this.http.delete(this.build.getApiLink() + `web/categories/${categoryId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  getCategoriesOrders(): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/categories/orders`, {
      observe: 'response'
    });
  }

  setCategoriesOrders(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/categories/orders/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  getCategoriesNames(): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/categories/names`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  getCategoryProperties(categoryId): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/categories/properties/${categoryId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }
}
