import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
} from '@angular/material';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastrService } from 'ngx-toastr';
import { ReportRecord, Agency, User } from 'src/app/interfaces';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AgencyService } from 'src/app/services/agency.service';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';
import { ViewReportComponent } from 'src/app/modals/view-report/view-report.component';
import { Subscription } from 'rxjs';
import { TeamDistributorsService } from 'src/app/services/team-distributors.service';

export function parseDate(str: string) {
  const dateA: any = str && str.split(/[^0-9]/);
  const EAST = 4;

  return str
    ? new Date(
        dateA[0],
        dateA[1] - 1,
        dateA[2],
        dateA[3] - EAST,
        dateA[4],
        dateA[5]
      )
    : '---';
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportsComponent implements OnInit, OnDestroy {
  headerTitle = 'STUD-E TEAM Reports';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  nowDate = new Date();
  dateFromControl = new FormControl();
  dateToControl = new FormControl();
  @ViewChild('dateFrom', { static: true }) dateFrom: ElementRef;
  @ViewChild('dateTo', { static: true }) dateTo: ElementRef;
  isLoadingResults = false;
  reportsData = [];
  agencies: Agency[] = [{ id: -1, name: 'All Agencies' }];
  selectedAgency: Agency = { id: -1, name: 'All Agencies' };
  users: User[] = [{ id: -1, name: 'All Users' }];
  distributors: any[] = [{ id: -1, name: 'All TEAM Distributors' }];
  allUsers = [];
  selectedUser: User = { id: -1, name: 'All Users' };
  selectedDistributor = { id: -1, name: 'All TEAM Distributors' };
  dataSource: any;
  displayedColumns: string[] = [
    'creationTime',
    'reportName',
    'submittedBy',
    'customer',
    'estimatedPrice',
    'actions',
  ];
  user = null;
  aSub: Subscription;

  constructor(
    public reportsService: ReportsService,
    public headerDataService: HeaderDataService,
    private toastr: ToastrService,
    private userService: UserService,
    private agencyService: AgencyService,
    private teamDistributorsService: TeamDistributorsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.headerDataService.setTitle(this.headerTitle);
    this.aSub = this.userService.userChanged$.subscribe((user) => {
      this.user = user;
      if (user) {
        if (user.distributorName) {
          this.headerDataService.setTitle(user.distributorName + ' Reports');
        }
        setTimeout(() => {
          if (user.role == 'ADMIN') {
            this.displayedColumns = [
              'creationTime',
              'reportName',
              'submittedBy',
              'teamDistributor',
              'customer',
              'estimatedPrice',
              'actions',
            ];
          }
        }, 777);
      }
    });
    this.getUserData();
    this.getUsersData();
    // this.getAgenciesData();
    this.getTeamDistributorsData();
    this.getReportsData();
  }

  ngOnDestroy() {
    this.reportsService.setAgency('');
    this.reportsService.setUser('');
    this.reportsService.setDateFrom('');
    this.reportsService.setDateTo('');
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

  getReportsData() {
    this.isLoadingResults = true;
    this.reportsService.setPage(this.paginator.pageIndex);
    this.reportsService.setSize(
      this.paginator.pageSize || this.reportsService.getSize()
    );
    this.reportsService.getReports().subscribe(
      (response) => {
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoadingResults = false;
      },
      (error) => {
        // console.warn('getReportsData err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' +
            (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
      }
    );
  }

  async getUserData() {
    await this.userService.getCurrentUser().toPromise();
  }

  getUsersData() {
    this.userService.getUsersNames().subscribe((users) => {
      this.allUsers = this.users.concat(users.body);
      this.users = this.users.concat(users.body);
    });
  }

  getAgenciesData() {
    this.agencyService.getAgenciesNames().subscribe((agencies) => {
      this.agencies = this.agencies.concat(agencies.body);
    });
  }

  getTeamDistributorsData() {
    this.teamDistributorsService
      .getTeamDistributorsNames()
      .subscribe((data) => {
        this.distributors = data.body;
      });
  }

  prepareDataSourse(data: ReportRecord[]) {
    // console.warn('prepareDataSourse data -> ', data); // example
    this.reportsData = JSON.parse(JSON.stringify(data)).map((item) => {
      item.updated = parseDate(item.updated).toLocaleString();
      item.clientCreated = parseDate(item.clientCreated).toLocaleString();

      return item;
    });

    this.dataSource = new MatTableDataSource(this.reportsData);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'creationTime':
          return new Date(item.clientCreated);
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.reportsService.setOrderBy(event.active);
    this.reportsService.setOrdering(event.direction);
    this.getReportsData();
  }

  changeAgency(event) {
    this.reportsService.setAgency(event ? event.id : event);
    if (event && event.managers && event.representatives) {
      this.users = event.managers.concat(event.representatives);
      this.selectedUser = { id: -1, name: 'All Users' };
      this.reportsService.setUser('');
    } else {
      this.users = this.allUsers;
    }
    this.getReportsData();
  }

  changeUser(event) {
    this.reportsService.setUser(event ? event.id : event);
    this.getReportsData();
  }

  changeDistributor(event) {
    this.reportsService.setDistributor(event ? event.id : event);
    if (event && event.distributorAdmins && event.distributorRepresentatives) {
      this.users = event.distributorAdmins.concat(event.distributorRepresentatives);
      this.selectedUser = { id: -1, name: 'All Users' };
      this.reportsService.setUser('');
    } else {
      this.users = this.allUsers;
    }
    this.getReportsData();
  }

  changeDate(typeDate: string, event) {
    if (typeDate === 'dateFrom') {
      this.reportsService.setDateFrom(event ? +new Date(event.value) : event);
    }
    if (typeDate === 'dateTo') {
      this.reportsService.setDateTo(
        event ? +new Date(event.value) + 24 * 60 * 60 * 1000 - 1000 : event
      );
    }
    this.getReportsData();
  }

  escapeDatepicker(type) {
    if (type === 'dateFrom') {
      this.dateFromControl.setValue(null);
      this.changeDate('dateFrom', false);
      this.dateFrom.nativeElement.blur();
    }
    if (type === 'dateTo') {
      this.dateToControl.setValue(null);
      this.changeDate('dateTo', false);
      this.dateTo.nativeElement.blur();
    }
  }

  getReportCSV(report: ReportRecord) {
    this.reportsService.getReportCSV(report.id).subscribe(
      (result) => {
        // console.warn('result CSV', result);
      },
      (error) => {
        // console.warn('error CSV', error);
        this.toastr.error(
          'Something went wrong. ' +
            (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
      }
    );
  }

  getReportPDF(report: ReportRecord) {
    this.isLoadingResults = true;
    this.reportsService.getReportPDF(report.id).subscribe(
      (result) => {
        // console.warn('result getReportPDF ++');

        let newBlob = new Blob([result.body], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = report.name
          .replace(/[\.\\/:*?\"<>|]/g, '_')
          .replace(/(?:\r\n|\r|\n)/g, ' ');
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        this.isLoadingResults = false;

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (error) => {
        // console.warn('error getReportPDF', error);
        this.toastr.error(
          'Something went wrong. ' +
            (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
        this.isLoadingResults = false;
      }
    );
  }

  deleteReport(report: ReportRecord) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Delete report',
        message: 'Are you sure you want to delete report?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      },
    });

    dialogRef.afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        this.reportsService.deleteReport(report.id).subscribe(
          (result) => {
            // console.warn('result DEL report', result);
            this.getReportsData();
          },
          (error) => {
            // console.warn('error DEL report', error);
            this.toastr.error(
              'Something went wrong. ' +
                (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }

  preview(report: ReportRecord) {
    const dialogRef = this.dialog.open(ViewReportComponent, {
      width: '94vw',
      maxWidth: '94vw',
      height: '96vh',
      panelClass: 'preview-report-modal',
      data: {
        report,
      },
    });
  }
}
