import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { ReportExecutiveSummaryService } from 'src/app/services/report-executive-summary.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-report-executive-summary',
  templateUrl: './report-executive-summary.component.html',
  styleUrls: ['./report-executive-summary.component.scss'],
})
export class ReportExecutiveSummaryComponent implements OnInit {
  isLoading = false;
  inProgress = false;
  headerTitle = 'STUD-E TEAM Executive Summary';
  executiveSummaryText = '';
  aSub: Subscription;
  user;

  constructor(
    private reportExecutiveSummaryService: ReportExecutiveSummaryService,
    private toastr: ToastrService,
    public headerDataService: HeaderDataService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.headerDataService.setTitle(this.headerTitle);
    this.aSub = this.userService.userChanged$.subscribe((user) => {
      this.user = user;
      if (user) {
        if (user.distributorName) {
          this.headerDataService.setTitle(
            user.distributorName + ' Executive Summary'
          );
        }
      }
    });
    this.getData();
  }

  ngOnDestroy() {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

  async getData() {
    this.isLoading = true;
    try {
      this.executiveSummaryText = await this.reportExecutiveSummaryService
        .getExecutiveSummary()
        .toPromise();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.toastr.error('Something went wrong', 'Oops!');
      this.isLoading = false;
    }
  }

  async submitUpdate() {
    this.inProgress = true;
    try {
      await this.reportExecutiveSummaryService
        .setExecutiveSummary(this.executiveSummaryText)
        .toPromise();
      this.inProgress = false;
      this.toastr.success('Updated!', '');
    } catch (error) {
      console.log(error);
      this.toastr.error('Something went wrong', 'Oops!');
      this.inProgress = false;
    }
  }
}
