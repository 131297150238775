import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { DistributorsService } from 'src/app/services/distributors.service';

@Component({
  selector: 'app-import-csv',
  templateUrl: './import-csv.component.html',
  styleUrls: ['./import-csv.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportCsvComponent implements OnInit {
  files: NgxFileDropEntry[] = [];
  infoData = '';
  fileToImport;

  constructor(
    public dialogRef: MatDialogRef<ImportCsvComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastr: ToastrService,
    private distributorsService: DistributorsService
  ) {}

  ngOnInit() {}

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // console.log(droppedFile.relativePath, fileEntry);
          // console.log(droppedFile.relativePath, file);
          if (file.type === 'text/plain' || /(\.csv)/g.test(file.name)) {
            this.fileToImport = file;
            this.infoData = file.name;
          } else {
            this.toastr.warning(`Wrong file type "${droppedFile.relativePath}"`, 'Attention!');
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  fileOver(event) {
    // console.log(event);
  }

  fileLeave(event) {
    // console.log(event);
  }

  cancel() {
    this.dialogRef.close();
  }

  importCsv() {
    this.distributorsService.importDistributorCsv(this.data.distributor.id, { file: this.fileToImport }).subscribe(
      result => {
        // console.warn('result importDistributorCsv', result);
      },
      error => {
        // console.warn('error importDistributorCsv', error);
        this.toastr.error(
          'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
      }
    );
  }
}
