import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';
import { CodeReferenceService } from 'src/app/services/code-reference.service';
import { CodeReferenceModalComponent } from 'src/app/modals/code-reference-modal/code-reference-modal.component';
import { CodeReferenceGroupsModalComponent } from 'src/app/modals/code-reference-groups-modal/code-reference-groups-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-code-references',
  templateUrl: './code-references.component.html',
  styleUrls: ['./code-references.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CodeReferencesComponent implements OnInit {
  headerTitle = 'Code Reference Management';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoadingResults = false;
  codeReferencesData = [];
  dataSource: any;
  displayedColumns: string[] = ['name', 'description', 'url', 'actions'];
  actions: string[] = ['Import Code References', 'Export Code References', 'Import Code References Products', 'Export Code References Products'];
  showButton;

  constructor(
    public codeReferenceService: CodeReferenceService,
    public headerDataService: HeaderDataService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.headerDataService.setTitle(this.headerTitle);
    this.getCodeReferencesData();
  }

  getCodeReferencesData() {
    this.isLoadingResults = true;
    this.codeReferenceService.setPage(this.paginator.pageIndex);
    this.codeReferenceService.setSize(this.paginator.pageSize || this.codeReferenceService.getSize());
    this.codeReferenceService.getCodeReferences().subscribe(
      response => {
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoadingResults = false;
      },
      error => {
        // console.warn('getCodeReferences err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
      }
    );
  }

  prepareDataSourse(data: any[]) {
    // console.warn('prepareDataSourse data -> ', data); // example
    this.codeReferencesData = JSON.parse(JSON.stringify(data)).map(item => {
      return item;
    });

    this.dataSource = new MatTableDataSource(this.codeReferencesData);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.codeReferenceService.setOrderBy(event.active);
    this.codeReferenceService.setOrdering(event.direction);
    this.getCodeReferencesData();
  }

  createCodeReference() {
    const dialogRef = this.dialog.open(CodeReferenceModalComponent, {
      width: '93vw',
      height: '95vh',
      maxWidth: 'none',
      panelClass: 'code-reference-modal-panel',
      data: {
        title: 'New Code Reference'
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response === 'success') {
        this.getCodeReferencesData();
      }
    });
  }

  editCodeReference(codeReferece) {
    this.isLoadingResults = true;

    this.codeReferenceService.getCodeReference(codeReferece.id).subscribe(
      resp => {
        let codeRefereceData = JSON.parse(JSON.stringify(resp.body));

        const dialogRef = this.dialog.open(CodeReferenceModalComponent, {
          width: '93vw',
          height: '95vh',
          maxWidth: 'none',
          panelClass: 'code-reference-modal-panel',
          data: {
            title: 'Edit Code Reference ' + codeReferece.name,
            codeReference: codeRefereceData
          }
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response === 'success') {
            this.getCodeReferencesData();
          }
        });

        this.isLoadingResults = false;
      },
      error => {
        this.isLoadingResults = false;
      }
    );
  }

  deleteCodeReference(codeReferece) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Delete Code Referece',
        message: 'Are you sure you want to delete Code Referece?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.codeReferenceService.deleteCodeReference(codeReferece.id).subscribe(
          result => {
            // console.warn('result DEL codeReferece', result);
            this.getCodeReferencesData();
          },
          error => {
            // console.warn('error DEL codeReferece', error);
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }

  showCodeReferencesGroups() {
    const dialogRef = this.dialog.open(CodeReferenceGroupsModalComponent, {
      width: '50vw',
      maxHeight: '95vh',
      panelClass: 'code-reference-groups-modal-panel'
    });
  }

  importCodeReferences(event) {
    if (event && event.target) {
      let file = event.target.files[0];
      if (file) {
        if (file.name.match(/\.csv$/g)) {
          this.isLoadingResults = true;
          this.codeReferenceService.importCodeReferences(file).subscribe(
            result => {
              // console.warn('result importCodeReferences ++', result);
              this.isLoadingResults = false;
              this.getCodeReferencesData();
            },
            error => {
              // console.warn('error importCodeReferences', error);
              this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
              this.isLoadingResults = false;
            }
          );
        } else {
          this.toastr.warning('Wrong file type', 'Attention!');
        }
      }
    }
  };

  exportCodeReferences() {
    this.isLoadingResults = true;
    this.codeReferenceService.exportCodeReferences().subscribe(
      result => {
        // console.warn('result exportCodeReferences ++');

        let newBlob = new Blob([result.body], { type: 'text/csv' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = 'exported_references ' + moment().format('MMMM Do YYYY, h:mm:ss a').replace(/(?:\r\n|\r|\n)/g, ' ') + '.csv';
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        this.isLoadingResults = false;

        setTimeout(function() {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      error => {
        // console.warn('error exportCodeReferences', error);
        this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
        this.isLoadingResults = false;
      }
    );
  }

  importCodeReferencesProducts(event) {
    if (event && event.target) {
      let file = event.target.files[0];
      if (file) {
        if (file.name.match(/\.csv$/g)) {
          this.isLoadingResults = true;
          this.codeReferenceService.importCodeReferencesProducts(file).subscribe(
            result => {
              // console.warn('result importCodeReferencesProducts ++', result);
              this.isLoadingResults = false;
              this.getCodeReferencesData();
            },
            error => {
              // console.warn('error importCodeReferencesProducts', error);
              this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
              this.isLoadingResults = false;
            }
          );
        } else {
          this.toastr.warning('Wrong file type', 'Attention!');
        }
      }
    }
  };

  exportCodeReferencesProducts() {
    this.isLoadingResults = true;
    this.codeReferenceService.exportCodeReferencesProducts().subscribe(
      result => {
        // console.warn('result exportCodeReferencesProducts ++');

        let newBlob = new Blob([result.body], { type: 'text/csv' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = 'exported_products ' + moment().format('MMMM Do YYYY, h:mm:ss a').replace(/(?:\r\n|\r|\n)/g, ' ') + '.csv';
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        this.isLoadingResults = false;

        setTimeout(function() {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      error => {
        // console.warn('error exportCodeReferencesProducts', error);
        this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
        this.isLoadingResults = false;
      }
    );
  }

  changeAction(event) {
    if (event) {
      this.showButton = this.actions.indexOf(event);
    }
  }
}
