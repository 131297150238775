import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  currentUser;
  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.currentUser = this.userService.getServiceUser() || "loadingUser";

    if (this.currentUser) {
      if (
        this.currentUser === "loadingUser" ||
        (route.data.roles &&
          route.data.roles.some(
            (permission) =>
              (this.currentUser &&
                this.currentUser.role &&
                this.currentUser.role.includes(permission)) ||
              permission === "all"
          ))
      ) {
        return true;
      }
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
