import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface InfoPopupComponent {
  title: string;
  content: string;
}

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<InfoPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: InfoPopupComponent) {}

  ngOnInit() {}

  cancel(): void {
    this.dialogRef.close();
  }
}
