import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidebarDataService } from 'src/app/services/sidebar-data.service';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportDataComponent implements OnInit {
  activeSidebarSection = 'Products';

  constructor(private sidebarData: SidebarDataService) {}

  ngOnInit() {
    this.sidebarData.setOpenedSection(this.activeSidebarSection);
  }
}
