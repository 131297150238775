import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root'
})
export class TeamDistributorsService {
  page = 0;
  size = 10;
  orderBy = "name";
  ordering = "desc";

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }


  getTeamDistributors(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() +
        `web/teamDistributors?page=${this.page}&size=${this.size}&orderBy=${
          this.orderBy
        }&ordering=${this.ordering}`,
      {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
        observe: "response",
      }
    );
  }
  
  createTeamDistributor(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/teamDistributors/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }
  
  updateTeamDistributor(distributorId, body): Observable<any> {
    return this.http.put(this.build.getApiLink() + `web/teamDistributors/${distributorId}`, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  deleteTeamDistributor(distributorId: string): Observable<any> {
    return this.http.delete(
      this.build.getApiLink() + `web/teamDistributors/${distributorId}`,
      {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
        observe: "response",
      }
    );
  }

  getTeamDistributorsNames(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/teamDistributors/list', { observe: 'response' });
  }

  getTeamDistributorCurrent(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/teamDistributors/current', { observe: 'response' });
  }

  getTeamDistributorsAll(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/teamDistributors/names', { observe: 'response' });
  }
}
