import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-view-team-distributor-logo",
  templateUrl: "./view-team-distributor-logo.component.html",
  styleUrls: ["./view-team-distributor-logo.component.scss"],
})
export class ViewTeamDistributorLogoComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ViewTeamDistributorLogoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  cancel(): void {
    this.dialogRef.close();
  }
}
