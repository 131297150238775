import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/interfaces';
import userRoles from 'src/app/config-data/roles';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { isItemNotInArray } from 'src/app/global-functions';
import { AgencyService } from 'src/app/services/agency.service';
import { TeamDistributorsService } from 'src/app/services/team-distributors.service';

@Component({
  selector: 'app-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserInfoModalComponent implements OnInit {
  isLoading = false;
  user: User;
  currentUser: User;
  title = '';
  userName = '';
  userEmail = '';
  userPhone = '';
  userPassword;
  roles = [];
  selectedRole;
  showRecommendations = new FormControl(true);
  showTemplates = new FormControl(true);
  savePhoto = new FormControl();
  showDistributors = new FormControl();
  allAgencies = [];
  selectedAgency;
  allUsers = [];
  selectedManager;
  allDistributors = [];
  selectedDistributor;
  hideRoleAndDistAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<UserInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastr: ToastrService,
    private userService: UserService,
    private teamDistributorsService: TeamDistributorsService,
    private agencyService: AgencyService
  ) {}

  ngOnInit() {
    this.roles = userRoles.filter((role) => role.id !== -1);

    if (this.data && this.data.currentUser) {
      this.currentUser = this.data.currentUser;
      if (this.currentUser.role == 'ADMIN_DISTRIBUTOR') {
        this.hideRoleAndDistAdmin = true;
        this.selectedRole = {
          name: 'Representative Distributor',
          type: 'REPRESENTATIVE_DISTRIBUTOR',
        };
        this.selectedManager = this.currentUser;
      }
    }

    if (this.data && this.data.title) {
      this.title = this.data.title;
    }

    // this.agencyService.getAgenciesNames().subscribe((agencies) => {
    //   this.allAgencies = agencies.body;
    //   // console.warn('this.allAgencies > ', this.allAgencies);
    // });

    if (this.data && this.data.user) {
      this.isLoading = true;
      this.userService.getUser(this.data.user.id).subscribe(
        (response) => {
          this.user = response.body;
          if (this.title == 'Edit User Settings') {
            this.selectedManager = this.user.associatedManager || '';
          }
          this.prepareData();
          this.isLoading = false;
          // console.warn('loaded user >> ', this.user);
        },
        (error) => {
          // console.warn('error GET user', error);
          this.toastr.error(
            'Something went wrong. ' +
              (error.error && error.error.message ? error.error.message : ''),
            'Oops!'
          );
          this.isLoading = false;
        }
      );
    } else {
      // this.selectedRole = this.roles[0];
    }
  }

  async prepareData() {
    this.userName = this.user.name;
    this.userEmail = this.user.email;
    this.userPhone = this.user.phone;
    this.userPassword = '';
    this.selectedRole = this.roles.find((role) => role.type === this.user.role);
    this.showRecommendations.setValue(this.user.showRecommendations);
    this.showTemplates.setValue(this.user.showTemplates);
    this.showDistributors.setValue(this.user.showDistributors);

    if (this.user.role !== 'ADMIN') {
      this.savePhoto.setValue(this.user.savePhoto);
    }

    if (
      (this.user.role === 'REPRESENTATIVE' || this.user.role === 'A_MANAGER') &&
      this.user.agency
    ) {
      this.selectedAgency = this.allAgencies.find(
        (agency) => agency.id === this.user.agency.id
      );
    }
    if (this.user.role === 'REPRESENTATIVE' && this.user.associatedManager) {
      this.allUsers = this.allAgencies.find(
        (agency) => agency.id === this.user.agency.id
      ).managers;
      this.selectedManager = this.allUsers.find(
        (user) => user.id === this.user.associatedManager.id
      );
    }
    if (this.user.role === 'A_MANAGER' && this.user.associatedManager) {
      this.isLoading = true;
      this.userService.getUsersNames('J_MANAGER').subscribe((users) => {
        this.allUsers = users.body;
        this.selectedManager = this.allUsers.find(
          (user) => user.id === this.user.associatedManager.id
        );
        // console.warn('this.allUsers > ', this.allUsers);
        this.isLoading = false;
      });
    }
    if (
      this.user.role === 'ADMIN_DISTRIBUTOR' &&
      this.user.assignedId &&
      this.currentUser.role == 'ADMIN'
    ) {
      this.isLoading = true;
      let distributors = await this.teamDistributorsService
        .getTeamDistributorsAll()
        .toPromise();
      this.allDistributors = distributors.body;
      this.selectedDistributor = this.allDistributors.find(
        (distributor) => distributor.id === this.user.assignedId
      );
      // console.warn('this.allUsers > ', this.allUsers);
      this.isLoading = false;

      this.isLoading = true;
      let users = await this.userService.getUsersNames('J_MANAGER').toPromise();
      this.allUsers = users.body;
      this.selectedManager = this.allUsers.find(
        (user) =>
          this.user.associatedManager &&
          user.id === this.user.associatedManager.id
      );
      // console.warn('this.allUsers > ', this.allUsers);
      this.isLoading = false;
    }
    if (
      this.user.role === 'ADMIN_DISTRIBUTOR' &&
      !this.user.assignedId &&
      this.currentUser.role == 'ADMIN'
    ) {
      this.isLoading = true;
      let users = await this.userService.getUsersNames('J_MANAGER').toPromise();
      this.allUsers = users.body;
      // console.warn('this.allUsers > ', this.allUsers);
      this.isLoading = false;
    }
    if (
      this.user.role === 'REPRESENTATIVE_DISTRIBUTOR' &&
      this.user.assignedId
    ) {
      this.isLoading = true;
      this.userService.getUsersNames('ADMIN_DISTRIBUTOR').subscribe((users) => {
        this.allUsers = users.body;
        this.selectedManager = this.allUsers.find(
          (user) => user.id === this.user.assignedId
        );
        // console.warn('this.allUsers > ', this.allUsers);
        this.isLoading = false;
      });
    }
  }

  saveUser() {
    this.checkData()
      .then(() => {
        this.isLoading = true;

        let data: any = {
          name: this.userName,
          email: this.userEmail,
          phone: this.userPhone,
          password: this.userPassword,
          role: this.selectedRole.type,
          showDistributors: this.showDistributors.value,
          showRecommendations: this.showRecommendations.value,
          showTemplates: this.showTemplates.value,
        };

        if (this.selectedRole.type === 'J_MANAGER') {
          data.savePhoto = this.savePhoto.value;
        }

        if (this.selectedRole.type === 'A_MANAGER') {
          data.savePhoto = this.savePhoto.value;
          data.agencyId = this.selectedAgency.id;
          data.managerId = this.selectedManager.id;
        }

        if (this.selectedRole.type === 'ADMIN_DISTRIBUTOR') {
          data.teamDistributorId = this.selectedDistributor
            ? this.selectedDistributor.id
            : this.user && this.user.assignedId
            ? this.user.assignedId
            : '';
          data.justriteManagerId = this.selectedManager
            ? this.selectedManager.id
            : '';
        }

        if (this.selectedRole.type === 'REPRESENTATIVE_DISTRIBUTOR') {
          data.adminId = this.selectedManager ? this.selectedManager.id : '';
        }

        if (this.selectedRole.type === 'REPRESENTATIVE') {
          data.savePhoto = this.savePhoto.value;
          data.agencyId = this.selectedAgency.id;
          data.managerId = this.selectedManager ? this.selectedManager.id : '';
        }

        if (this.data.user) {
          if (data.password === '') delete data.password;

          this.userService.updateUser(this.user.id, data).subscribe(
            (response) => {
              // console.warn('response UPDATE USER', response);

              if (response.status === 200) {
                this.isLoading = false;
                this.dialogRef.close(true);
                this.toastr.info('User was successfully updated!', 'Info');
              }
            },
            (error) => {
              // console.warn('error UPDATE USER', error);
              this.toastr.error(
                'Something went wrong. ' + error.error.message,
                'Oops!'
              );
              this.isLoading = false;
            }
          );
        } else {
          this.userService.createUser(data).subscribe(
            (response) => {
              // console.warn('response CREATE USER', response);

              if (response.status === 200) {
                this.isLoading = false;
                this.dialogRef.close(true);
                this.toastr.info('User was successfully created!', 'Info');
              }
            },
            (error) => {
              // console.warn('error CREATE USER', error);
              this.toastr.error(
                'Something went wrong. ' + error.error.message,
                'Oops!'
              );
              this.isLoading = false;
            }
          );
        }
      })
      .catch((list) => {
        this.toastr.warning(list, 'Attention!', {
          enableHtml: true,
          timeOut: 4000,
        });
      });
  }

  async checkData() {
    let errors = [];

    if (!this.selectedRole && isItemNotInArray(errors, 'Role is required.')) {
      errors.push('Role is required.');
    }
    if (
      (!this.userName || this.userName.trim() === '') &&
      isItemNotInArray(errors, "User's name is required.")
    ) {
      errors.push("User's name is required.");
    }
    if (
      (!this.userEmail || this.userEmail.trim() === '') &&
      isItemNotInArray(errors, "User's email is required.")
    ) {
      errors.push("User's email is required.");
    }
    if (
      (!this.userPhone || this.userPhone.trim() === '') &&
      isItemNotInArray(errors, "User's phone is required.")
    ) {
      errors.push("User's phone is required.");
    }
    if (
      (!this.userPassword || this.userPassword.trim() === '') &&
      isItemNotInArray(errors, "User's password is required.") &&
      !this.data.user
    ) {
      errors.push("User's password is required.");
    }
    if (
      this.selectedRole &&
      (this.selectedRole.type === 'A_MANAGER' ||
        this.selectedRole.type === 'REPRESENTATIVE') &&
      !this.selectedAgency &&
      isItemNotInArray(errors, 'Agency is required.')
    ) {
      errors.push('Agency is required.');
    }
    if (
      this.selectedRole &&
      this.selectedRole.type === 'A_MANAGER' &&
      !this.selectedManager &&
      isItemNotInArray(errors, 'Manager is required.')
    ) {
      errors.push('Manager is required.');
    }
    if (
      this.selectedRole &&
      this.selectedRole.type === 'REPRESENTATIVE_DISTRIBUTOR' &&
      !this.selectedManager &&
      isItemNotInArray(errors, 'Distributor Admin is required.')
    ) {
      errors.push('Distributor Admin is required.');
    }
    if (
      this.selectedRole &&
      this.selectedRole.type === 'ADMIN_DISTRIBUTOR' &&
      !this.selectedDistributor &&
      !this.hideRoleAndDistAdmin &&
      isItemNotInArray(errors, 'Team Distributor is required.')
    ) {
      errors.push('Team Distributor is required.');
    }

    // console.info('checked data', {
    //   selectedRole: this.selectedRole,
    //   userName: this.userName,
    //   userEmail: this.userEmail,
    //   userPhone: this.userPhone,
    //   userPassword: this.userPassword,
    //   selectedAgency: this.selectedAgency,
    //   selectedManager: this.selectedManager
    // });

    if (errors.length > 0) {
      let list = '<ul>';
      errors.forEach((error) => {
        list += `<li>${error}</li>`;
      });
      list += '<ul>';
      return Promise.reject(list);
    } else {
      return Promise.resolve();
    }
  }

  async changeRole(event) {
    // console.warn('changeRole ', event);
    this.selectedManager = undefined;
    this.selectedAgency = undefined;

    if (event && event.type === 'A_MANAGER') {
      this.isLoading = true;
      this.userService.getUsersNames('J_MANAGER').subscribe((users) => {
        this.allUsers = users.body;
        // console.warn('this.allUsers > ', this.allUsers);
        this.isLoading = false;
      });
    }
    if (event && event.type === 'REPRESENTATIVE_DISTRIBUTOR') {
      this.isLoading = true;
      this.userService.getUsersNames('ADMIN_DISTRIBUTOR').subscribe((users) => {
        this.allUsers = users.body;
        // console.warn('this.allUsers > ', this.allUsers);
        this.isLoading = false;
      });
    }
    if (event && event.type === 'ADMIN_DISTRIBUTOR') {
      this.isLoading = true;
      let distributors = await this.teamDistributorsService
        .getTeamDistributorsAll()
        .toPromise();
      this.allDistributors = distributors.body;
      let users = await this.userService.getUsersNames('J_MANAGER').toPromise();
      this.allUsers = users.body;
      // console.warn('this.allUsers > ', this.allUsers);
      this.isLoading = false;
    }
  }

  changeAgency(event) {
    // console.warn('changeAgency ', event);
    if (this.selectedRole.type === 'REPRESENTATIVE') {
      if (event) {
        this.allUsers = event.managers;
      } else {
        this.allUsers = [];
      }
      this.selectedManager = undefined;
    }
  }
}
