import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  page = 0;
  size = 10;
  orderBy = 'name';
  ordering = 'desc';
  name = '';
  category = '';
  manufacture = '';
  teamId = '';
  pick = false;

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getName() {
    return this.name;
  }

  public setCategory(category: string) {
    this.category = category;
  }

  public getCategory() {
    return this.category;
  }

  public setManufacture(manufacture: string) {
    this.manufacture = manufacture;
  }

  public getManufacture() {
    return this.manufacture;
  }

  public setTeamId(name: string) {
    this.teamId = name;
  }

  public getTeamId() {
    return this.teamId;
  }

  public setPick(bool: boolean) {
    this.pick = bool;
  }

  public getPick() {
    return this.pick;
  }

  getProducts(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() +
        `web/products?page=${this.page}&size=${this.size}&orderBy=${
          this.orderBy
        }&ordering=${this.ordering}${this.name ? '&name=' + this.name : ''}${
          this.category && +this.category !== -1
            ? '&category=' + this.category
            : ''
        }${
          this.manufacture && +this.manufacture !== -1
            ? '&manufacture=' + this.manufacture
            : ''
        }${this.teamId ? '&teamId=' + this.teamId : ''}${
          this.pick ? '&pick=' + this.pick : ''
        }`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  getProduct(productId): Observable<any> {
    return this.http.get(
      this.build.getApiLink() + `web/products/${productId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  createProduct(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/products/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response',
    });
  }

  updateProduct(productId, body): Observable<any> {
    return this.http.put(
      this.build.getApiLink() + `web/products/${productId}`,
      body,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  deleteProduct(productId: string | number): Observable<any> {
    return this.http.delete(
      this.build.getApiLink() + `web/products/${productId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  searchProduct(string): Observable<any> {
    return this.http.get(
      this.build.getApiLink() + `web/products/search?text=${string}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  addToEnabledProducts(teamId, id): Observable<any> {
    return this.http.post(
      this.build.getApiLink() + `web/products/add/${id}?teamId=${teamId}`,
      {},
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  deleteFromEnabledProducts(teamId, id): Observable<any> {
    return this.http.delete(
      this.build.getApiLink() + `web/products/team/${id}?teamId=${teamId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  batchSelect(teamId, body): Observable<any> {
    return this.http.post(
      this.build.getApiLink() + `web/products/upload?teamId=${teamId}`,
      body,
      {
        observe: 'response',
      }
    );
  }

  getBatchSelectFile(body): Observable<any> {
    return this.http.post(
      this.build.getApiLink() + `web/products/download/`,
      body,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  addBatchSelectFile(body): Observable<any> {
    return this.http.post(
      this.build.getApiLink() + `web/products/upload/add/`,
      body,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  downloadExample(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() + `web/products/downloadExample/`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  updateTeamDistributorPartNumber(
    teamId,
    productId,
    teamDistributorPartNumber
  ): Observable<any> {
    return this.http.put(
      this.build.getApiLink() +
        `web/products/${productId}/team/${teamId}/partNumber`,
      {
        teamDistributorPartNumber,
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  deleteTeamDistributorPartNumber(teamId, productId): Observable<any> {
    return this.http.delete(
      this.build.getApiLink() + `web/products/${productId}/team/${teamId}/partNumber`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }
}
