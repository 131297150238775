import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource
} from "@angular/material";
import { SidebarDataService } from "src/app/services/sidebar-data.service";
import { AgencyService } from "src/app/services/agency.service";
import { ToastrService } from "ngx-toastr";
import { HeaderDataService } from "src/app/services/header-data.service";
import { PromptPopupComponent } from "src/app/dialogs/prompt-popup/prompt-popup.component";
import { ConfirmPopupComponent } from "src/app/dialogs/confirm-popup/confirm-popup.component";
import { InfoPopupComponent } from "src/app/dialogs/info-popup/info-popup.component";

@Component({
  selector: "app-agency-management",
  templateUrl: "./agency-management.component.html",
  styleUrls: ["./agency-management.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AgencyManagementComponent implements OnInit {
  headerTitle = "Agencies Management";
  activeSidebarSection = "Users";
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoadingResults = false;
  agenciesData = [];
  dataSource: any;
  displayedColumns: string[] = ["name", "managers", "actions"];

  constructor(
    public headerDataService: HeaderDataService,
    private toastr: ToastrService,
    public agencyService: AgencyService,
    public dialog: MatDialog,
    private sidebarData: SidebarDataService
  ) {}

  ngOnInit() {
    this.headerDataService.setTitle(this.headerTitle);
    this.sidebarData.setOpenedSection(this.activeSidebarSection);
    this.getAgenciesData();
  }

  getAgenciesData() {
    this.isLoadingResults = true;
    this.agencyService.setPage(this.paginator.pageIndex);
    this.agencyService.setSize(
      this.paginator.pageSize || this.agencyService.getSize()
    );
    this.agencyService.getAgencies().subscribe(
      response => {
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoadingResults = false;
      },
      error => {
        // console.warn("getAgenciesData err ->", error); // example
        this.toastr.error(
          "Something went wrong. " +
            (error.error && error.error.message ? error.error.message : ""),
          "Oops!"
        );
      }
    );
  }

  prepareDataSourse(data: any[]) {
    // console.warn("prepareDataSourse data -> ", data); // example
    this.agenciesData = JSON.parse(JSON.stringify(data)).map(item => {
      item.managersNames = item.managers
        .map(manager => manager.name)
        .join(", ");
      return item;
    });

    this.dataSource = new MatTableDataSource(this.agenciesData);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        // case 'created':
        // return new Date(item.created);
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.agencyService.setOrderBy(event.active);
    this.agencyService.setOrdering(event.direction);
    this.getAgenciesData();
  }

  addNewAgency() {
    const dialogRef = this.dialog.open(PromptPopupComponent, {
      panelClass: "panel-prompt",
      data: {
        title: "Create new Agency",
        message: "Please input Agency name",
        cancelButtonText: "Cancel",
        confirmButtonText: "Create"
      }
    });

    dialogRef.afterClosed().subscribe(agencyName => {
      if (agencyName) {
        let newAgency = {
          name: agencyName
        };
        this.agencyService.createAgency(newAgency).subscribe(
          response => {
            // console.warn("createAgency response ->", response);
            this.toastr.info("Agency was successfully created!", "Info");
            this.getAgenciesData();
          },
          error => {
            // console.warn("createAgency err ->", error); // example
            this.toastr.error(
              "Something went wrong. " +
                (error.error && error.error.message ? error.error.message : ""),
              "Oops!"
            );
          }
        );
      }
    });
  }

  editAgency(agency) {
    const dialogRef = this.dialog.open(PromptPopupComponent, {
      panelClass: "panel-prompt",
      data: {
        title: "Edit Agency name",
        message: "Please input new Agency name",
        cancelButtonText: "Cancel",
        confirmButtonText: "Save",
        inputData: agency.name
      }
    });

    dialogRef.afterClosed().subscribe(agencyName => {
      if (agencyName) {
        let newAgency = {
          name: agencyName
        };
        this.agencyService.updateAgency(agency.id, newAgency).subscribe(
          response => {
            // console.warn("editAgency response ->", response);
            this.toastr.info("Agency was successfully created!", "Info");
            this.getAgenciesData();
          },
          error => {
            // console.warn("editAgency err ->", error); // example
            this.toastr.error(
              "Something went wrong. " +
                (error.error && error.error.message ? error.error.message : ""),
              "Oops!"
            );
          }
        );
      }
    });
  }

  deleteAgency(agency) {
    // console.warn("deleteagency > ", agency);

    if (agency.representatives.length > 0 || agency.managers.length > 0) {
      let content = "";
      if (agency.managers.length) {
        content += "<h3>Managers List</h3>";
        content += `<ul>${agency.managers
          .map(i => "<li>" + i.name + "</li>")
          .join("_*_*_")
          .replace(/\_\*\_\*\_/g, "")}</ul>`;
      }
      if (agency.representatives.length) {
        content += "<h3>Representatives List</h3>";
        content += `<ul>${agency.representatives
          .map(i => "<li>" + i.name + "</li>")
          .join("_*_*_")
          .replace(/\_\*\_\*\_/g, "")}</ul>`;
      }

      const dialogRef = this.dialog.open(InfoPopupComponent, {
        maxWidth: "50vw",
        data: {
          title:
            "Please re-assign following users to a different agency prior removal",
          content
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        width: "auto",
        data: {
          title: "Delete Agency",
          message: "Are you sure you want to delete agency?",
          cancelButtonText: "No",
          confirmButtonText: "Yes"
        }
      });

      dialogRef.afterClosed().subscribe(confirmation => {
        if (confirmation) {
          this.agencyService.deleteAgency(agency.id).subscribe(
            result => {
              // console.warn("result DEL agency", result);
              this.getAgenciesData();
            },
            error => {
              // console.warn("error DEL agency", error);
              this.toastr.error(
                "Something went wrong. " +
                  (error.error && error.error.message
                    ? error.error.message
                    : ""),
                "Oops!"
              );
            }
          );
        }
      });
    }
  }
}
