import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ceateDataFromBlob, isItemNotInArray } from 'src/app/global-functions';
import { TeamDistributorsService } from 'src/app/services/team-distributors.service';
import { ImageCropComponent } from '../image-crop/image-crop.component';
import * as uuid from 'uuid';
import { MediaService } from 'src/app/services/media.service';

@Component({
  selector: 'app-team-distributor',
  templateUrl: './team-distributor.component.html',
  styleUrls: ['./team-distributor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TeamDistributorComponent implements OnInit {
  type = 'new';
  isLoading = false;
  title = 'TEAM Distributor';
  distributor;
  teamDistributorName;
  teamDistributorLogo;
  teamDistributorLogoName;
  isLoadingLogo = false;

  constructor(
    public dialogRef: MatDialogRef<TeamDistributorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private mediaService: MediaService,
    private teamDistributorsService: TeamDistributorsService
  ) {}

  ngOnInit() {
    if (this.data && this.data.type) {
      this.type = this.data.type;
      if (this.type != 'new') {
        this.title = 'Edit TEAM Distributor';
      }
    }
    if (this.data && this.data.distributor) {
      this.distributor = this.data.distributor;
      this.teamDistributorName = this.distributor.name;
      this.teamDistributorLogoName = this.distributor.pdfLogo;
      if (this.distributor.pdfLogo) {
        this.getLogo(this.distributor);
      }
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event) {
    let file = event.target.files[0];
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png'
    ) {
      if (file.size < 10000000) {
        const dialogRef = this.dialog.open(ImageCropComponent, {
          width: '94vw',
          maxWidth: '600px',
          maxHeight: '94vh',
          panelClass: 'image-crop-panel',
          data: {
            event,
            format: 'jpeg',
          },
        });

        dialogRef.afterClosed().subscribe((image) => {
          if (image) {
            this.teamDistributorLogo = image;
            this.teamDistributorLogoName = file.name;
          }
        });
      } else {
        this.toastr.warning(
          `Max image size ${Math.round(10000000 / 1024)}kb.`,
          'Attention!'
        );
      }
    } else {
      this.toastr.warning('Wrong file type', 'Attention!');
    }
  }

  clearImage() {
    this.teamDistributorLogo = null;
    this.teamDistributorLogoName = '';
  }

  saveDistributor() {
    let data: any = {
      name: '',
      photo: null,
    };

    let errors = [];

    if (
      !this.teamDistributorName &&
      isItemNotInArray(errors, 'Distributor Name is required.')
    ) {
      errors.push('Distributor Name is required.');
    }

    if (errors.length > 0) {
      let list = '<ul>';
      errors.forEach((error) => {
        list += `<li>${error}</li>`;
      });
      list += '<ul>';

      this.toastr.warning(list, 'Attention!', {
        enableHtml: true,
        timeOut: 4000,
      });
    } else {
      data.name = this.teamDistributorName;
      if (this.data.distributor) {
        data.id = this.data.distributor.id;
      }

      if (this.data && this.data.distributor) {
        if (this.teamDistributorLogo && this.teamDistributorLogoName) {
          if (this.teamDistributorLogoName !== this.data.distributor.pdfLogo) {
            data.photo = {
              media: this.teamDistributorLogo.replace(
                /data:(image|application)\/(jpeg|png|jpg|json);base64/,
                ''
              ),
              name: uuid.v4() + '__' + this.teamDistributorLogoName,
            };
          } else {
            data.photo = {
              name: this.teamDistributorLogoName,
            };
          }
        }
      } else if (this.teamDistributorLogo && this.teamDistributorLogoName) {
        data.photo = {
          media: this.teamDistributorLogo.replace(
            /data:(image|application)\/(jpeg|png|jpg|json);base64/,
            ''
          ),
          name: uuid.v4() + '__' + this.teamDistributorLogoName,
        };
      }

      // console.warn('data >> ', data);

      this.isLoading = true;
      if (this.data && this.data.distributor) {
        this.teamDistributorsService
          .updateTeamDistributor(this.data.distributor.id, data)
          .subscribe(
            (response) => {
              // console.log(response);
              this.isLoading = false;
              this.dialogRef.close('success');
            },
            (error) => {
              // console.warn('updateDistributor err ->', error); // example
              this.toastr.error(
                'Something went wrong. ' +
                  (error.error && error.error.errors
                    ? error.error.errors.join(', ')
                    : error.error.message
                    ? error.error.message
                    : ''),
                'Oops!'
              );
              this.isLoading = false;
            }
          );
      } else {
        this.teamDistributorsService.createTeamDistributor(data).subscribe(
          (response) => {
            // console.log(response);
            this.isLoading = false;
            this.dialogRef.close('success');
          },
          (error) => {
            // console.warn('createDistributor err ->', error); // example
            this.toastr.error(
              'Something went wrong. ' +
                (error.error && error.error.errors
                  ? error.error.errors.join(', ')
                  : error.error.message
                  ? error.error.message
                  : ''),
              'Oops!'
            );
            this.isLoading = false;
          }
        );
      }
    }
  }

  getLogo(distributor) {
    this.isLoadingLogo = true;
    this.mediaService.getMedia(distributor.pdfLogo, 'distributor').subscribe(
      (result) => {
        if (result.status === 200) {
          ceateDataFromBlob(result.body).then(
            (data) => {
              this.teamDistributorLogo = data;
              this.isLoadingLogo = false;
            },
            (err) => {
              this.isLoadingLogo = false;
              // console.warn('ceateDataFromBlob err ', err);
            }
          );
        }
      },
      (err) => {
        this.isLoadingLogo = false;
        // console.warn('mediaService.getMedia err ', err);
      }
    );
  }
}
