import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { CodeReferenceGroupService } from 'src/app/services/code-reference-group.service';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PromptPopupComponent } from 'src/app/dialogs/prompt-popup/prompt-popup.component';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-code-reference-groups-modal',
  templateUrl: './code-reference-groups-modal.component.html',
  styleUrls: ['./code-reference-groups-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CodeReferenceGroupsModalComponent implements OnInit {
  isLoading;
  isSaveGroup;
  newCodeReferenceGroupName;
  codeReferenceGroups = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['name', 'actions'];

  constructor(public codeReferenceGroupService: CodeReferenceGroupService, private toastr: ToastrService, public dialog: MatDialog) {}

  ngOnInit() {
    this.getCodeReferenceGroupsData();
  }

  getCodeReferenceGroupsData() {
    this.isLoading = true;
    this.codeReferenceGroupService.setPage(this.paginator.pageIndex);
    this.codeReferenceGroupService.setSize(this.paginator.pageSize || this.codeReferenceGroupService.getSize());
    this.codeReferenceGroupService.getCodeReferenceGroups().subscribe(
      response => {
        // console.warn('response', response);
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoading = false;
      },
      error => {
        // console.warn('getCodeReferenceGroups err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
        this.isLoading = false;
      }
    );
  }

  prepareDataSourse(data: any[]) {
    // console.warn('prepareDataSourse data -> ', data); // example
    this.codeReferenceGroups = JSON.parse(JSON.stringify(data))
      .filter(item => item.active)
      .map(item => {
        return item;
      });

    this.dataSource = new MatTableDataSource(this.codeReferenceGroups);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.codeReferenceGroupService.setOrderBy(event.active);
    this.codeReferenceGroupService.setOrdering(event.direction);
    this.getCodeReferenceGroupsData();
  }

  addNewCodeReferenceGroup() {
    if (!this.newCodeReferenceGroupName || this.newCodeReferenceGroupName.trim() === '') {
      this.toastr.warning('Code reference group name is required!', 'Attention!');
    } else {
      this.codeReferenceGroupService.createCodeReferenceGroup({ name: this.newCodeReferenceGroupName }).subscribe(
        response => {
          // console.warn('createCodeReferenceGroup response ->', response); // example
          this.getCodeReferenceGroupsData();
          this.toastr.info('Code reference group was successfully created!', 'Info');
          this.newCodeReferenceGroupName = '';
        },
        error => {
          // console.warn('createCodeReferenceGroup err ->', err); // example
          this.toastr.error(
            'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
            'Oops!'
          );
        }
      );
    }
  }

  editGroup(group) {
    const dialogRef = this.dialog.open(PromptPopupComponent, {
      width: 'auto',
      data: {
        title: `Rename ${group.name} group`,
        message: 'Please input new group name',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Rename',
        maxLength: 250,
        inputData: group.name
      }
    });

    dialogRef.afterClosed().subscribe(groupName => {
      if (groupName) {
        this.codeReferenceGroupService.updateCodeReferenceGroup(group.id, { name: groupName }).subscribe(
          res => {
            // console.warn('updateCodeReferenceGroup res ->', res); // example
            this.getCodeReferenceGroupsData();
            this.toastr.info('Code reference group was successfully renamed!', 'Info');
          },
          error => {
            // console.warn('updateCodeReferenceGroup err ->', err); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }

  deleteGroup(group) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Delete code reference group',
        message: 'Are you sure you want to delete group?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.codeReferenceGroupService.deleteCodeReferenceGroup(group.id).subscribe(
          result => {
            // console.warn('result DEL group', result);
            this.toastr.info('Code reference group was successfully removed!', 'Info');
            this.getCodeReferenceGroupsData();
          },
          error => {
            // console.warn('error DEL group', error);
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }
}
