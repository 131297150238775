import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from 'src/app/services/products.service';
import { CodeReferenceGroupService } from 'src/app/services/code-reference-group.service';
import { CodeReferenceService } from 'src/app/services/code-reference.service';
import * as uuid from 'uuid';
import { isItemNotInArray } from 'src/app/global-functions';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-code-reference-modal',
  templateUrl: './code-reference-modal.component.html',
  styleUrls: ['./code-reference-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CodeReferenceModalComponent implements OnInit {
  isLoading = false;
  isLoadingProducts = false;
  codeReference;
  codeReferenceName = '';
  codeReferenceUrl = '';
  codeReferencesGroups = [];
  selectedCodeReferenceGroup;
  codeReferenceDescription = '';
  codeReferenceDetails = '';
  codeReferenceSections = '';
  codeReferenceProducts = [];
  newProductSearchInput = '';
  newProduct;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['partNumber', 'name', 'actions'];
  filteredOptionsProducts: any[];
  typingTimer;
  doneTypingInterval = 666;

  constructor(
    public dialogRef: MatDialogRef<CodeReferenceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public productsService: ProductsService,
    public сodeReferenceGroupService: CodeReferenceGroupService,
    public сodeReferenceService: CodeReferenceService
  ) {}

  ngOnInit() {
    this.getCodeReferenceGroups();
    if (this.data && this.data.codeReference) {
      this.codeReference = this.data.codeReference;
      this.prepareData();
    }
  }

  getCodeReferenceGroups() {
    let oldSize = this.сodeReferenceGroupService.getSize();
    this.isLoading = true;

    this.сodeReferenceGroupService.setSize(9999);
    this.сodeReferenceGroupService.getCodeReferenceGroups().subscribe(
      response => {
        // console.warn('response', response);
        this.codeReferencesGroups = response.body.data;
        this.isLoading = false;
        this.сodeReferenceGroupService.setSize(oldSize);
      },
      error => {
        // console.warn('getCodeReferenceGroups err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
        this.isLoading = false;
        this.сodeReferenceGroupService.setSize(oldSize);
      }
    );
  }

  prepareData() {
    this.codeReferenceName = this.data.codeReference.name;
    this.codeReferenceUrl = this.data.codeReference.url;
    this.selectedCodeReferenceGroup = this.data.codeReference.codeReferenceGroup;
    this.codeReferenceDescription = this.data.codeReference.description;
    this.codeReferenceDetails = this.data.codeReference.details;
    this.codeReferenceSections = this.data.codeReference.sections;

    if (this.data.codeReference.products && this.data.codeReference.products.length > 0) {
      this.codeReferenceProducts = JSON.parse(JSON.stringify(this.data.codeReference.products));
      this.dataSource = new MatTableDataSource(this.codeReferenceProducts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  changeCodeReferenceGroup(event) {}

  changeSorting(event) {}

  deleteProduct(product) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Delete product',
        message: 'Are you sure you want to delete product?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.codeReferenceProducts = this.codeReferenceProducts.filter(item => item.id !== product.id);

        this.dataSource = new MatTableDataSource(this.codeReferenceProducts);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  addNewProduct() {
    if (this.newProduct) {
      if (isItemNotInArray(this.codeReferenceProducts, this.newProduct.id, 'id')) {
        this.codeReferenceProducts.push({
          id: this.newProduct.id,
          name: this.newProduct.name,
          description: this.newProduct.description,
          partNumber: this.newProduct.partNumber
        });

        this.dataSource = new MatTableDataSource(this.codeReferenceProducts);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.newProduct = undefined;
        this.newProductSearchInput = '';
      } else {
        this.toastr.warning('Product already is in the list.', 'Attention!');
      }
    } else {
      this.toastr.warning('Please select product from the list', 'Attention!');
    }
  }

  save() {
    let data: any = {
      description: '',
      details: '',
      name: '',
      groupId: null,
      url: '',
      sections: ''
      // productIds: []
    };

    let errors = [];

    if ((!this.codeReferenceName || this.codeReferenceName.trim() === '') && isItemNotInArray(errors, 'Code Reference Name is required.')) {
      errors.push('Code Reference Name is required.');
    }
    if (
      (!this.codeReferenceDescription || this.codeReferenceDescription.trim() === '') &&
      isItemNotInArray(errors, 'Code Reference Description is required.')
    ) {
      errors.push('Code Reference Description is required.');
    }
    if (
      (!this.codeReferenceDetails || this.codeReferenceDetails.trim() === '') &&
      isItemNotInArray(errors, 'Code Reference Details is required.')
    ) {
      errors.push('Code Reference Details is required.');
    }
    if (!this.selectedCodeReferenceGroup && isItemNotInArray(errors, 'Code Reference Group is required.')) {
      errors.push('Code Reference Group is required.');
    }

    if (errors.length > 0) {
      let list = '<ul>';
      errors.forEach(error => {
        list += `<li>${error}</li>`;
      });
      list += '<ul>';

      this.toastr.warning(list, 'Attention!', {
        enableHtml: true,
        timeOut: 4000
      });
    } else {
      data.description = this.codeReferenceDescription;
      data.details = this.codeReferenceDetails;
      data.name = this.codeReferenceName;

      if (this.selectedCodeReferenceGroup) {
        data.groupId = this.selectedCodeReferenceGroup.id;
      }

      if (this.codeReferenceUrl) {
        data.url = this.codeReferenceUrl;
      }

      if (this.codeReferenceSections) {
        data.sections = this.codeReferenceSections;
      }

      if (this.codeReferenceProducts.length > 0) {
        data.productIds = this.codeReferenceProducts.map(product => product.id);
      }

      // console.warn('data >> ', data);

      this.isLoading = true;
      if (this.data && this.data.codeReference) {
        this.сodeReferenceService.updateCodeReference(this.data.codeReference.id, data).subscribe(
          response => {
            // console.log(response);
            this.isLoading = false;
            this.dialogRef.close('success');
          },
          error => {
            // console.warn('updateCodeReference err ->', error); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
            this.isLoading = false;
          }
        );
      } else {
        this.сodeReferenceService.createCodeReference(data).subscribe(
          response => {
            // console.log(response);
            this.isLoading = false;
            this.dialogRef.close('success');
          },
          error => {
            // console.warn('createCodeReference err ->', error); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
            this.isLoading = false;
          }
        );
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  newProductSelected(event) {
    // console.warn('newProductSelected >> ', event);
    this.newProduct = event.option.value;
    this.newProductSearchInput = event.option.value.description;
  }

  getProducts() {
    let filter = this.newProductSearchInput;
    this.filteredOptionsProducts = [];
    this.newProduct = undefined;
    
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (filter && filter.length > 2) {
        this.isLoadingProducts = true;
        this.productsService.searchProduct(filter).subscribe(
          response => {
            this.filteredOptionsProducts = response.body;
            this.isLoadingProducts = false;
          },
          error => {
            // console.warn('getProducts err ->', error); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
            this.isLoadingProducts = false;
          }
        );
      } else {
        this.toastr.info('Please enter minimum 3 characters', 'Info');
      }
    }, this.doneTypingInterval);
  }
}
